import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class AccountingTaxationCompliance extends Component {
    render() {
      document.title = 'SMS : : Services - business-advisory - Accounting Taxation Compliance';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - business-advisory - Accounting Taxation Compliance';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - business-advisory - Accounting Taxation Compliance';
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Accounting and Taxation Compliance</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link to="/">Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/business-advisory">Business Advisory</Link></li> <li>Accounting and Taxation Compliance</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Accounting and Taxation Compliance </h1>
              </div> 
              <p>The tax compliance and reporting regulations prove to be a time consuming and complex entity. That said, to make your work easier, we always provide the business owners the secured assistance in terms of the management of the increasing number of compliance issues. We can also identify the risk and the significant penalties for failing to deliver. We can work with you at all times to make the key decisions regarding the business. </p>
              <p>We always make the Compliance that works as an additive for allowing you to focus on bringing the best management and growth of the business. We can always work from starting to the end for the assistance of your company. At work, we always involve the in-house bookkeepers and Accountants whenever needed. We always continue to research the needs of the clients to proactively ensure that everything comes under the proper control and procedure.</p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/accounting-and-taxation-compliance.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h1 className="title">The assistance we provide</h1>
            <p>We ensure the preparation of the management accounts. Besides, we also prepare and lodge the tax returns. Tax Planning and tax service make us the best. We can give the corporate secretarial duties as well as business advisory statements with the other statutory report requirements.</p>
            <h1 className="title">What makes it special?</h1>
            <ul className="ser-list">
              <li>We can hold the transparency in the service and do everything with initial support and the set-up of the accounting system as well and trust.</li>
              <li>We have refined ourselves in terms of bookkeeping accounting and control for the legal entries also. Such prospects make us the best.</li>
              <li>We can prepare the tax returns required by the legislation as a preparation of the annual financial statements that stand in accordance with the accounting standards and international financial reporting standards. The quality we hold makes us remarkable.</li>
            </ul>
            <p>If you want other kinds of services related to tax compliance, it is time to give us a call. We can work appropriately for your benefit.</p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default AccountingTaxationCompliance