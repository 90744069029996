import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class CapitalGainsTax extends Component {
    render() {
      document.title = 'SMS : : Services - Tax-Services - Capital Gains Tax';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - Tax-Services - Capital Gains Tax';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - Tax-Services - Capital Gains Tax';
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Capital Gains Tax (CGT)</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/tax-services">Tax Services</Link></li> <li>Capital Gains Tax (CGT)</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Capital Gains Tax (CGT)
                </h1>
              </div>
              <p>Capital Gains Tax gets charged in respect of gains made from selling, transferring or otherwise disposing of assets. That said, for the proper accounting services, we can provide you with the amazing staff to work effortlessly. We understand how many common assets become subject to Capital Gains Tax (CGT). Some of the entities that are associated with the same are like stocks, bonds, and precious metals. </p>
              <p>We understand how the legislation for CGT is a complex area. For keeping it off your shoulders as a burden, we can provide you with the support of the Accountant who will be actively working with your company. They are capable of preparing your CGT computations, claims as well as reliefs that you may be entitled to. Besides, we also assist in calculating any liability that may be due. </p>
              <h3 className="title">The Type of Services We Offer</h3>
              <p>When you are considering selling a personal asset or everything else as a part of your business, you can get our assistance because our staff will ensure advising you of the tax planning opportunities available to you. Besides, they can assist you in every possible way before you make your disposal. We do so to mitigate or reduce potential tax liabilities. We will also see that you only pay for the support and services that you are opting for. With that, you can get the long term approach for your assistance as well. </p> 
              <p>So, be ready to choose the right accounting services for taxation today. With us, you can rest assured of getting the specialised support. Over the years, we have been working with independent businesses and clients. Each Accountant runs their own business and is passionate about supporting you at all circumstances.</p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/Capital-Gains-Tax.jpg"} alt="Compliance Tax" /></div></div>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default CapitalGainsTax