import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class OutsourcedAccountingSolutions extends Component {
    render() {
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Outsourced Accounting Solutions</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/business-advisory">Business Advisory</Link></li> <li>Outsourced Accounting Solutions</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Outsourced Accounting Solutions</h1>
              </div>
              <p>We can provide high-quality services for accounting as the team of the outsourcing accounting company. We also handle human resource solutions that will be set at the first meeting with your specific needs. Even if you want a couple of hours a month or customizable services you can get it with us. The accounting experience that you will be getting with us as the most probable team of experts. We always give our time in fostering the level of integrity, honesty, and confidentiality in the working procedure.</p> 
              <p>We can work for financial bookkeeping or statement preparation and analysis. The budgeting and forecasting we offer also comes with the collection of the annual budget and track of the monthly variances in accounts receivable and accounts payable. Maintenance and verification that we provide ensure accuracy and timeliness. We can give the certified online assistance with extensive desktop experience as well.</p> 
              <p>The controller services are also available with the other business. We bring continuous improvement in the practices and policies to ensure proper management. At all levels, we can also provide you with the human resources payroll benefit coordination that will be experienced and working with the cost accounting job’s costing and the variance analysis as well.</p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/outsourced-accounting-solutions.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h1 className="title">The promises we will make</h1>
            <h3 className="title">Data Confidentiality</h3>
            <p>In work, we always maintain the sensitive points of the business in a confidential manner and always undertake necessary measures that will be ensuring protection all time. The precautionary measures that we undertake also make us the best.</p>
            <h3 className="title">Quick Start</h3>
            <p>With us, you can get the expert solutions that will be working as per the contract every day.</p>
            <h3 className="title">Flexibility</h3>
            <p>We are the professionals who will give flexible plans for the specific needs of your business. An approach that we utilize with the solutions always makes us take care of the expectations of the clients, and thus we serve in the form the virtual team.</p>
            <h3 className="title">Business Continuity</h3>
            <p>We always look for the business entities that will be running without distraction. We are the team of experts who are well trained in working on the project and also the completion of the project. We ensure consideration of the test procedures and technological expertise that will be helping us to operate flexibly even during the uncertain times.</p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default OutsourcedAccountingSolutions