import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class RDTax extends Component {
    render() {
      document.title = 'SMS : : Services - Tax-Services - RD Tax';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - Tax-Services - RD Tax';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - Tax-Services - RD Tax';
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">R&D Tax and Government Incentives</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/tax-services">Tax Services</Link></li> <li>R&D Tax and Government Incentives</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">R&amp;D Tax and Government Incentives </h1>
              </div> 
              <p>The Identity incentive structure comes inclusive of the accounting and other professionals who can work with the flexible approach that is also evolving at a rapid pace. These days, plenty of countries acknowledge the importance of tax incentives and support innovation, establish new industries, and lead to the growing knowledge economy. That said, the professionals can help in the problem management of the incentives.</p>
              <p>We can work for the identification of the R&amp;D credit review, the tax and legal matters relating to intellectual property ownership. Besides, we can also help in the identification of the Government Grant for the sustainability and innovation and investment project. We also advise the companies on the optimization of the Global R&amp;D credit. Our teams can help in complying with the changing R&amp;D landscape with the maximum return on investment.</p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/r&d-tax-and-government-incentives.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <p>The R&amp;D incentive professionals can always work with extensive industry knowledge and services in diverse technical backgrounds. We can also work with information technology science, engineering, taxation, and accounting. We can also work to give you the best tips, advice, and support across the different industry sectors.</p>
            <h3 className="title">The Best Part of Our Team</h3>
            <p>We can always work to meet the needs of our clients. Our team of dedicated professionals always holds expertise from diverse disciplines that include mathematics, accounting, and law. Our team members can always ensure positioning the claims and tailoring them to the profile. We can offer the flexible fee options available at competitive rates that also comes inclusive of the policymakers who can hold the unique approach towards review support. </p>
            <p>In the goal of professional accounting, we also consider the extending of the tax credits. We can also consider the incentive that will be available to the organization and with expertise. We've also helped in properly processing the compliance obligations associated with the tax credits and other incentives.</p>
            <p>If you want assistance with our team, get access to our services today because we've honed our skills over the years. </p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default RDTax