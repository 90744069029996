import React,  { Component } from 'react';
import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import UsefullLinks from './pages/UsefullLinks';
import BlogDetails from './pages/single/BlogDetails';
import Contact from './pages/Contact';
import MakeAppointment from './pages/MakeAppointment';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ScrollToTop from "./common/ScrollToTop";
import BusinessAdvisory from './pages/innerpages/services/BusinessAdvisory';
import SpecialistServices from './pages/innerpages/services/SpecialistServices';
import TaxService from './pages/innerpages/services/TaxService';
import OnlineAccounting from './pages/innerpages/services/OnlineAccounting';
import PayrollAccounting from './pages/innerpages/services/PayrollAccounting';
import ComplianceServices from './pages/innerpages/services/ComplianceServices';
import SuperannuationAccounting from './pages/innerpages/services/SuperannuationAccounting';
import BookkeepingServices from './pages/innerpages/services/BookkeepingServices';
import AccountingTaxationCompliance from './pages/innerpages/services/bussiness-advisory/AccountingTaxationCompliance';
import BusinessBuyingSelling from './pages/innerpages/services/bussiness-advisory/BusinessBuyingSelling';
import BusinessFinancialStructures from './pages/innerpages/services/bussiness-advisory/BusinessFinancialStructures';
import CashFlowForecasting from './pages/innerpages/services/bussiness-advisory/CashFlowForecasting';
import CorporateGovernance from './pages/innerpages/services/bussiness-advisory/CorporateGovernance';
import InternationalBusinessAdvisory from './pages/innerpages/services/bussiness-advisory/InternationalBusinessAdvisory';
import OutsourcedAccountingSolutions from './pages/innerpages/services/bussiness-advisory/OutsourcedAccountingSolutions';
import PerformanceImprovement from './pages/innerpages/services/bussiness-advisory/PerformanceImprovement';
import StrategicPlanning from './pages/innerpages/services/bussiness-advisory/StrategicPlanning';
import SuccessionPlanning from './pages/innerpages/services/bussiness-advisory/SuccessionPlanning';


import CapitalGainsTax from './pages/innerpages/services/tax-services/CapitalGainsTax';
import ComplianceTax from './pages/innerpages/services/tax-services/ComplianceTax';
import FringeBenefitsTax from './pages/innerpages/services/tax-services/FringeBenefitsTax';
import GoodsServicesTax from './pages/innerpages/services/tax-services/GoodsServicesTax';
import IncomeTaxReturnPreparation from './pages/innerpages/services/tax-services/IncomeTaxReturnPreparation';
import IndirectTax from './pages/innerpages/services/tax-services/IndirectTax';
import RDTax from './pages/innerpages/services/tax-services/RDTax';
import StrategicTaxPlanning from './pages/innerpages/services/tax-services/StrategicTaxPlanning';
import TaxAuditPreparations from './pages/innerpages/services/tax-services/TaxAuditPreparations';
import TaxSolutions from './pages/innerpages/services/tax-services/TaxSolutions';
import TransferPricing from './pages/innerpages/services/tax-services/TransferPricing';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
class App extends Component {
  
  componentDidMount(){
     //An array of assets
     let scripts = [
         { src: "../assets/javascript/jquery.min.js" },
         { src: "../assets/javascript/bootstrap.min.js" },
         { src: "../assets/javascript/jquery.easing.js" },
         { src: "../assets/javascript/jquery-waypoints.js" },
         { src: "../assets/javascript/jquery-validate.js" },
         { src: "../assets/javascript/jquery.cookie.js" },
         { src: "../assets/javascript/owl.carousel.js" },
         { src: "../assets/javascript/jquery-countTo.js" },
         { src: "../assets/javascript/jquery.flexslider-min.js" },
         { src: "../assets/javascript/parallax.js" },
         { src: "../assets/javascript/modern.custom.js" },
         { src: "../assets/javascript/jquery.hoverdir.js" },
         { src: "../assets/javascript/jquery.fancybox.js" },
         { src: "../assets/javascript/main.js" },
         { src: "../assets/revolution/js/jquery.themepunch.tools.min.js" },
         { src: "../assets/revolution/js/jquery.themepunch.revolution.min.js" },
         { src: "../assets/revolution/js/slider.js" }
         
     ]
     //Append the script element on each iteration
     scripts.map(item => { 
         const script = document.createElement("script")
         script.src = item.src
         script.async = false
         document.head.appendChild(script)
     })    
   }
     render() {
  return (
    <Router>
     <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    <ScrollToTop/>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/about" component={About}/>
        <Route path="/contact-us" component={Contact}/>
        <Route path="/privacy-policy" component={PrivacyPolicy}/>

        <Route path="/useful-links" component={UsefullLinks}/>
        <Route path="/make-an-appointment" component={MakeAppointment}/>
        
        <Route path="/blog" component={Blog}/>
        <Route path="/blogs/:slug" component={BlogDetails}/>
        <Route path="/services/business-advisory" component={BusinessAdvisory}/>
        <Route path="/services/specialist-services" component={SpecialistServices}/>
        <Route path="/services/tax-services" component={TaxService}/>
        <Route path="/services/online-accounting" component={OnlineAccounting}/>
        <Route path="/services/payroll-accounting" component={PayrollAccounting}/>
        <Route path="/services/compliance-services" component={ComplianceServices}/>
        <Route path="/services/superannuation-accounting-services" component={SuperannuationAccounting}/>
        <Route path="/services/bookkeeping-services" component={BookkeepingServices}/>
        <Route path="/services/business-advisory-service/accounting-and-taxation-compliance" component={AccountingTaxationCompliance}/>
        <Route path="/services/business-advisory-service/business-buying-and-selling" component={BusinessBuyingSelling}/>
        <Route path="/services/business-advisory-service/business-and-financial-structures" component={BusinessFinancialStructures}/>
        <Route path="/services/business-advisory-service/cash-flow-forecasting" component={CashFlowForecasting}/>
        <Route path="/services/business-advisory-service/corporate-governance" component={CorporateGovernance}/>
        <Route path="/services/business-advisory-service/international-business-advisory" component={InternationalBusinessAdvisory}/>
        <Route path="/services/business-advisory-service/outsourced-accounting-solutions" component={OutsourcedAccountingSolutions}/>
        <Route path="/services/business-advisory-service/performance-improvement" component={PerformanceImprovement}/>
        <Route path="/services/business-advisory-service/strategic-planning" component={StrategicPlanning}/>
        <Route path="/services/business-advisory-service/succession-planning" component={SuccessionPlanning}/>

        <Route path="/services/tax-service/capital-gains-tax" component={CapitalGainsTax}/>
        <Route path="/services/tax-service/compliance-tax" component={ComplianceTax}/>
        <Route path="/services/tax-service/fringe-benefits-tax" component={FringeBenefitsTax}/>
        <Route path="/services/tax-service/goods-and-services-tax" component={GoodsServicesTax}/>
        <Route path="/services/tax-service/income-tax-return-preparation" component={IncomeTaxReturnPreparation}/>
        <Route path="/services/tax-service/indirect-tax" component={IndirectTax}/>
        <Route path="/services/tax-service/r&d-tax-and-government-incentives" component={RDTax}/>
        <Route path="/services/tax-service/strategic-tax-planning" component={StrategicTaxPlanning}/>
        <Route path="/services/tax-service/tax-audit-preparations-and-advisory" component={TaxAuditPreparations}/>
        <Route path="/services/tax-service/tax-solutions" component={TaxSolutions}/>
        <Route path="/services/tax-service/transfer-pricing" component={TransferPricing}/>

      </Switch> 
    </Router>
  );
  }
}

export default App;
