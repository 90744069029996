import React,  { Component, Fragment } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import MeetOurTeam from '../../wrapper/MeetOurTeam';
import AboutBanner from '../../assets/images/parallax/about-us.jpg';
import {Link} from "react-router-dom";
class About extends Component {
 //#Write some like this in the App.js for example, since it's the main component:
 
    render() {
      document.title = 'SMS : : About';
      document.getElementsByTagName("META")[3].content = 'SMS : : About';
      document.getElementsByTagName("META")[4].content = 'SMS : : About';
        return (
           <Fragment>
            <Header />
                {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${AboutBanner})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">About Us</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li>About</li>             
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="abutus">
          <div className="container">
            <div className="careers-info">
              <div className="row">
                <div className="col-lg-6">
                  <div className="careers-featured clearfix">
                    <div className="img"><img src={process.env.PUBLIC_URL + "./assets/images/services/c1.jpg"} alt="sr_img" /></div>
                    <div className="img2"><img src={process.env.PUBLIC_URL + "./assets/images/services/c2.jpg"} alt="sr_img" /></div>
                    <div className="img2"><img src={process.env.PUBLIC_URL + "./assets/images/services/c3.jpg"} alt="sr_img" /></div>
                  </div>
                </div>
                <div className="col-lg-6 align-self-center"> 
                  <div className="careers-text padding-left43 paratext2">
                    <div className="title-section style3 left">
                      <h1 className="title">SMS Accounting Partners</h1>
                    </div>
                    <p>  You can get the proper management of tax planning as well as financial planning when you have the SMS accounting partner by your side. That said, we can provide you a holistic solution with accounting tax and financial advisory made all under one roof. You can get the handling of money matters with someone who has a deep understanding of every client. When you have the total financial needs at your fingertips with our long-term professionals, you can rest assured that the investment decisions will be witnessing success. Our team of professionals will always offer a range of financial services in addition to accounting services.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="flat-row padingbotom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 history-text">
                <div className="title-section style3 left">
                  <h1 className="title">Who We Are</h1>
                </div>
                <p>We are the proud members of IPA and our team members together have over 15 years experience in providing business stable. Our financial strategies offer long term stability for our client’s focusing on proficiency, confidentiality, decency and efficacy.</p>
                <p>
We started our journey in the year 2012 and within few months, we have helped several major companies in framing best finance and business strategies for their company. With our offices based in Melbourne, Australia; we have passionately grown into a 3 offices association with offices in Melbourne City, Oakleigh, Dandenong and Craigeburn. Our team of expert accountants are highly dedicated and work to integrate our expertise and knowledge in the finance sector, business and other significant affairs of our clients. In this, we are able to provide a “hands on” solution from beginning to end of the accounting requirement for any client. Our major aim is to make sure that we provide our clients with the most lucrative and efficient business practices and financial strategies.</p>
<p>
Our financial strategies offer long term stability for our client’s businesses thus adding add value and success to our clients’ businesses by focusing on proficiency, confidentiality, decency and efficacy.</p>
              </div>
              {/* <div className="col-lg-6">
                <div className="main-history">
                  <div className="wrap-step clearfix">
                    <div className=" data-step float-left">
                      <span className="year">1990</span>
                    </div>
                    <div className=" info-step float-left">
                      <h5>Entrepreneurship Hall of Fame</h5>
                      <p>nother family member reinforced the younger man’s inter-est in the field. Joe’s uncle was an architect and a general contractor and encouraged Joe.</p>
                    </div>
                  </div>
                  <div className="wrap-step clearfix">
                    <div className=" data-step float-left">
                      <span className="year">2010</span>
                    </div>
                    <div className=" info-step float-left">
                      <h5>Entrepreneurship Hall of Fame</h5>
                      <p>nother family member reinforced the younger man’s inter-est in the field. Joe’s uncle was an architect and a general contractor and encouraged Joe.</p>
                    </div>
                  </div>
                  <div className="wrap-step clearfix">
                    <div className=" data-step float-left">
                      <span className="year">2018</span>
                    </div>
                    <div className=" info-step float-left">
                      <h5>Entrepreneurship Hall of Fame</h5>
                      <p>nother family member reinforced the younger man’s inter-est in the field. Joe’s uncle was an architect and a general contractor and encouraged Joe.</p>
                    </div>
                  </div>
                  <div className="wrap-step clearfix">
                    <div className=" data-step float-left">
                      <span className="year">2021</span>
                    </div>
                    <div className=" info-step float-left">
                      <h5>Entrepreneurship Hall of Fame</h5>
                      <p>nother family member reinforced the younger man’s inter-est in the field. Joe’s uncle was an architect and a general contractor and encouraged Joe.</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <MeetOurTeam/>
             <Footer/>
           </Fragment>
        )
    }
}

export default About