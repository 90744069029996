import React, { Component, Fragment} from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import AboutBanner from '../../../assets/images/parallax/about-us.jpg';
import { Link } from "react-router-dom";
import axios from 'axios';
import parse from 'html-react-parser';
import {API_URL} from '../../../config'
export default class BlogDetails extends Component {
    constructor(props){
        super(props);
        this.state={
            isLoading:true,
            postData:null
        }
    }
    getData = async (slug)=>{
        const res = await axios.get(`${API_URL}/api/post/${slug}`);
        if(res && res.data){
          if(res.data.status){
              this.setState({
                postData:res.data.post,
                isLoading:false
              })
          }
        }
        
      }
      componentDidMount(){
          const slug = this.props.match.params.slug;
          this.getData(slug);
      }
  render() {
      const {isLoading, postData} = this.state;
      document.title = !isLoading ? `SMS : : Blog - ${postData.title}` : 'SMS : : Blog';
      document.getElementsByTagName("META")[3].content = !isLoading ? `SMS : : Blog - ${postData.title}` : 'SMS : : Blog';
      document.getElementsByTagName("META")[4].content = !isLoading ? `SMS : : Blog - ${postData.title}` : 'SMS : : Blog';
    return (
        <Fragment>
           <Header />
           {
            isLoading ? <div className="container"> <p>Data loading...</p> </div> : <>
            <div className="page-title parallax" style={{ backgroundImage: `url(${postData.thumbnail})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">{postData.title}</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-calendar" />{postData.post_date}</li> 
                    <li>{postData.category.title}</li>         
                  </ul>                   
                </div>{/* /.breadcrumbs */}  
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="blog-page">
           <div className="container ck-content">
               { parse(postData.post_details)}
           </div>
        </section>
            </>
           }

           <Footer />
        </Fragment>
    );
  }
}
