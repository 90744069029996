import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import StarRatings from 'react-star-ratings';
const TestimonialSingle = ({ data }) => {
  return (
    <div className={ data.active === 1 ? 'testimonials style2 active' :  'testimonials style2'}> 
                    <div className="message">                                
                      <blockquote className="whisper">
                        "{data.blockquote}"
                      </blockquote>
                    </div>
                    <div className="avatar">
                      <div className="testimonial-author-thumbnail">    
                        <img src={process.env.PUBLIC_URL + data.avatar} alt="" />
                      </div> 
                      <div>
                      <span className="name">{ data.name }</span><br />
                      {/* <span className="position">{ data.position }</span> */}
                      <StarRatings
          rating={data.star}
          starRatedColor="#c09800"
          numberOfStars={5}
          name='rating'
          starDimension="20px"
           starSpacing="2px"
        />
                      </div>
                     
                    </div>                      
                  </div>
  );
};

TestimonialSingle.propTypes = {
  data: PropTypes.object,
  spaceBottomClass: PropTypes.string
};

export default TestimonialSingle;
