import PropTypes from "prop-types";
import React from "react";
import bannerData from "../data/why-chooge-us.json";
import WhyChoogeUsSingle from "../components/pages/single/WhyChoogeUsSingle";

const WhyChooseUs = () => {
  return (
<section className="flat-row v8 parallax5">
        <div className="section-overlay style3" />
        <div className="container">
          <div className="row">
            <div className="col-md-12">  
              <div className="title-section style3 text-center color-white">
                <h1 className="title">Why Choose Us</h1>
              </div>          
            </div>
          </div>      
          <ul id="data-effect" className="data-effect wrap-iconbox clearfix">{/* SLIDE  */}
        {/* SLIDE 1 */}
          {bannerData &&
            bannerData.map((single, key) => {
              return (
                <WhyChoogeUsSingle
                  data={single}
                  key={key}
                  spaceBottomClass="mb-30"
                />
              );
            })}
            </ul>
        </div>
      </section>
  );
};

WhyChooseUs.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default WhyChooseUs;
