import React,  { Component, Fragment } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import GoogleMap from '../common/GoogleMap';
import {Link} from "react-router-dom";
import slider1 from '../../assets/images/slides/2.jpg';
import about_image from '../../assets/images/services/video3.jpg';
import WhyChooseUs from '../../wrapper/WhyChooseUs';
import OurServices from '../../wrapper/OurServices';
import MeetOurTeam from '../../wrapper/MeetOurTeam';
import Testimonial from '../../wrapper/Testimonial';
import LatestBlog from '../../wrapper/LatestBlog';
import OurClients from '../../wrapper/OurClients';
import faqData from '../../data/faq.json'
class Home extends Component {

    render() {
      document.title = 'SMS : : Home';
      document.getElementsByTagName("META")[3].content = 'SMS : : Home';
      document.getElementsByTagName("META")[4].content = 'SMS : : Home';
        return (
           <Fragment>
            <Header />
            <div id="rev_slider_1078_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container slide-overlay" data-alias="classic4export" data-source="gallery" style={{margin: '0px auto', backgroundColor: 'transparent', padding: '0px', marginTop: '0px', marginBottom: '0px'}}>
        {/* START REVOLUTION SLIDER 5.3.0.2 auto mode */}
        <div id="rev_slider_1078_1" className="rev_slider fullwidthabanner" style={{display: 'none'}} data-version="5.3.0.2">
          <div className="slotholder" />
          <ul>{/* SLIDE  */}
            {/* SLIDE 1 */}
            <li data-index="rs-3050" data-transition="fade" data-slotamount={7} data-hideafterloop={0} data-hideslideonmobile="off" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed={2000} data-rotate={0} data-saveperformance="off" data-title="Ken Burns" data-param1 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>                        
              {/* <div class="overlay">
                            </div> */}
              {/* MAIN IMAGE */}
              <img src={slider1} alt="" data-bgposition="center center" data-kenburns="off" data-duration={30000} data-ease="Linear.easeNone" data-scalestart={100} data-scaleend={120} data-rotatestart={0} data-rotateend={0} data-offsetstart="0 0" data-offsetend="0 0" data-bgparallax={10} className="rev-slidebg" data-no-retina />
              {/* LAYERS */}
              {/* LAYER NR. 12 */}
              <div className="tp-caption title-slide" id="slide-3050-layer-1" data-x="['left','left','left','left']" data-hoffset="['35','20','50','50']" data-y="['middle','middle','middle','middle']" data-voffset="['-66','-66','-66','-66']" data-fontsize="['45','45','35','27']" data-lineheight="['55','55','45','32']" data-fontweight="['700','700','700','700']" data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames="[{&quot;delay&quot;:100,&quot;speed&quot;:3000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:1;&quot;,&quot;mask&quot;:&quot;x:[100%];y:0;s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[10,10,10,10]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0" data-paddingleft="[0,0,0,0]" style={{zIndex: 16, whiteSpace: 'nowrap'}}>Save <span>Time</span> And <span>Money</span>  <br /> Following <span> Your Growth </span><br /><span>Patterns</span>  And <span>Succeeding.</span>
              </div>
              <Link to="/about" target="_self" className="tp-caption flat-button text-center" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:[100%];s:inherit;e:inherit;&quot;,&quot;speed&quot;:1600,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1600,&quot;ease&quot;:&quot;Power4.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:[100%];&quot;,&quot;mask&quot;:&quot;x:inherit;y:inherit;s:inherit;e:inherit;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}]" data-x="['center','center','center','center']" data-hoffset="['-502','-400','-240','-70']" data-y="['middle','middle','middle','middle']" data-voffset="['102','102','80','50']" data-fontweight="['700','700','700','700']" data-width="['auto']" data-height="['auto']" style={{zIndex: 3}}>OUR company
              </Link>{/* END LAYER LINK */}
              l<Link to="/make-an-appointment" target="_self" className="tp-caption flat-button btn-contactus bg-theme text-center" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:[100%];s:inherit;e:inherit;&quot;,&quot;speed&quot;:1600,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1600,&quot;ease&quot;:&quot;Power4.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:[100%];&quot;,&quot;mask&quot;:&quot;x:inherit;y:inherit;s:inherit;e:inherit;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}]" data-x="['center','center','center','center']" data-hoffset="['-318','-200','-30','-70']" data-y="['middle','middle','middle','middle']" data-voffset="['102','102','80','110']" data-fontweight="['700','700','700','700']" data-width="['auto']" data-height="['auto']" style={{zIndex: 3}}>Book appointment
              </Link>{/* END LAYER LINK */}
            </li>
            {/* SLIDE 2 */}
            <li data-index="rs-3051" data-transition="fade" data-slotamount={7} data-hideafterloop={0} data-hideslideonmobile="off" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed={2000} data-rotate={0} data-saveperformance="off" data-title="Ken Burns" data-param1 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>                        
              {/* <div class="overlay">
                            </div> */}
              {/* MAIN IMAGE */}
              <img src={slider1} alt="" data-bgposition="center center" data-kenburns="off" data-duration={30000} data-ease="Linear.easeNone" data-scalestart={100} data-scaleend={120} data-rotatestart={0} data-rotateend={0} data-offsetstart="0 0" data-offsetend="0 0" data-bgparallax={10} className="rev-slidebg" data-no-retina />
              {/* LAYERS */}
              {/* LAYER NR. 12 */}
              <div className="tp-caption title-slide" id="slide-3050-layer-1" data-x="['left','left','left','left']" data-hoffset="['35','20','50','50']" data-y="['middle','middle','middle','middle']" data-voffset="['-66','-66','-66','-66']" data-fontsize="['45','45','35','27']" data-lineheight="['55','55','45','32']" data-fontweight="['700','700','700','700']" data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames="[{&quot;delay&quot;:100,&quot;speed&quot;:3000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:1;&quot;,&quot;mask&quot;:&quot;x:[100%];y:0;s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[10,10,10,10]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0" data-paddingleft="[0,0,0,0]" style={{zIndex: 16, whiteSpace: 'nowrap'}}>The Reliable  <br />  <span> SMS Accounting Partners</span><br />At Your Fingertips. 
              </div>
              <Link to="/about" target="_self" className="tp-caption flat-button text-center" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:[100%];s:inherit;e:inherit;&quot;,&quot;speed&quot;:1600,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1600,&quot;ease&quot;:&quot;Power4.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:[100%];&quot;,&quot;mask&quot;:&quot;x:inherit;y:inherit;s:inherit;e:inherit;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}]" data-x="['center','center','center','center']" data-hoffset="['-502','-400','-240','-70']" data-y="['middle','middle','middle','middle']" data-voffset="['102','102','80','50']" data-fontweight="['700','700','700','700']" data-width="['auto']" data-height="['auto']" style={{zIndex: 3}}>OUR company
              </Link>{/* END LAYER LINK */}
              <Link to="/make-an-appointment" target="_self" className="tp-caption flat-button btn-contactus bg-theme text-center" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:[100%];s:inherit;e:inherit;&quot;,&quot;speed&quot;:1600,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1600,&quot;ease&quot;:&quot;Power4.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:[100%];&quot;,&quot;mask&quot;:&quot;x:inherit;y:inherit;s:inherit;e:inherit;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}]" data-x="['center','center','center','center']" data-hoffset="['-318','-200','-30','-70']" data-y="['middle','middle','middle','middle']" data-voffset="['102','102','80','110']" data-fontweight="['700','700','700','700']" data-width="['auto']" data-height="['auto']" style={{zIndex: 3}}>Book appointment
              </Link>{/* END LAYER LINK */}
            </li>
          </ul>
        </div>
      </div>{/* END REVOLUTION SLIDER */}

      <section className="flat-row v9">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-about about-video margin-right_30">
                  <img src={ about_image } alt="image1" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="section-about padding-left70">
                <div className="title-section style2">
                  <h1 className="title"><span>Welcome to<br /> </span> SMS Accounting Partners</h1>
                  <div className="sub-title">
                  You can get the proper management of tax planning as well as financial planning when you have the SMS accounting partner by your side. That said, we can provide you with a holistic solution with accounting tax, and financial advisory made all under one roof. You can get the handling of money matters with someone who has a deep understanding of every client. When you have the total financial needs at your fingertips with our long-term professionals, you can rest assured that the investment decisions will be witnessing success. Our team of professionals will always offer a range of financial services in addition to accounting services.
                  </div>
                </div>
                <Link to="/about" className="flat-button color-white">Read more</Link>
              </div>
            </div>
          </div>
        </div>  
      </section>

      <WhyChooseUs/>
      <OurServices/>
      <MeetOurTeam/>


      <section className="flat-row background-nopara background-image1 section-counter">
          <div className="section-overlay style2" />
          <div className="container">
            <div className="row">
              <div className="col-md-12">  
                <div className="title-section style2 color-white text-center">
                  <h1 className="title">We advise you, you call <span>the right</span> decision!</h1>
                  <div className="sub-title">
                    We help entrepreneurs get their act together before they talk to investors.
                  </div>
                </div>          
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */} 
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="flat-counter text-center">                            
                  <div className="content-counter">
                    <div className="icon-count">
                      <i className="ti-pie-chart" />
                    </div>
                    <div className="numb-count" data-to={90} data-speed={2000} data-waypoint-active="yes">90</div>
                    <div className="name-count">Companies consulted</div>
                  </div>
                </div>{/* /.flat-counter */}
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="flat-counter text-center">                            
                  <div className="content-counter">
                    <div className="icon-count">
                      <i className="ti-headphone-alt" />
                    </div>
                    <div className="numb-count" data-to={120} data-speed={2000} data-waypoint-active="yes">120</div>
                    <div className="name-count">Consultants</div>
                  </div>
                </div>{/* /.flat-counter */}
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="flat-counter text-center">                            
                  <div className="content-counter">
                    <div className="icon-count">
                      <i className="ti-bookmark-alt" />
                    </div>
                    <div className="numb-count" data-to={50} data-speed={2000} data-waypoint-active="yes">50</div>
                    <div className="name-count">Awards Winning</div>
                  </div>
                </div>{/* /.flat-counter */}
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="flat-counter text-center">                            
                  <div className="content-counter">
                    <div className="icon-count">
                      <i className="ti-heart-broken" />
                    </div>
                    <div className="numb-count" data-to={240} data-speed={2000} data-waypoint-active="yes">240</div>
                    <div className="name-count">Satisfied Customers</div>
                  </div>
                </div>{/* /.flat-counter */}
              </div>
            </div>          
          </div>{/* /.container */} 
        </section> 
            <Testimonial/>
            <LatestBlog/>
        {/* <section className="section-maps-form wrap-blance blancejqurey2  parallax4 clearfix">
          <div className="section-overlay style2" />
          <div id="blance-s1" className="one-half flat-maps-form1">
            <div className="flat-maps">
              <GoogleMap/>
              
            </div> 
          </div>
          <div id="blance-s2" className="one-half flat-maps-form2 formrequest2">
            <div className="title-section style2 titlesize48 color-white">
              <h1 className="title"><span>Request</span> a call back.</h1>
              <div className="sub-title">
                Whatever specific financial, personal or investment opportuni-ties you’re looking for, be sure that the free consultation with one of our experts will sway you to signup!
              </div>
            </div> 
            <div className="wrap-formrequest">
              <form id="contactform" className="contactform wrap-form style2 clearfix" method="post" action="./contact/contact-process2.php" noValidate="novalidate">
                <span className="title-form">I would like to discuss:</span>
                <span className="flat-input flat-select">
                  <select>
                    <option value>How can we help:*</option>
                    <option value>How can we help:*</option>
                    <option value>How can we help:*</option>
                  </select>
                </span>
                <span className="flat-input"><input name="phone" type="text" defaultValue placeholder="Phone Number:*" required="required" /></span>
                <span className="flat-input"><input name="name" type="text" defaultValue placeholder="Your Name:*" required="required" /></span>
                <span className="flat-input"><button name="submit" type="submit" className="flat-button" id="submit" title="Submit now">SUBMIT<i className="fa fa-angle-double-right" /></button></span>
              </form>
            </div>
          </div>
        </section> */}
        <section className="flat-row background-nopara background-image1 section-counter">
          <div className="section-overlay style2" />
          <div className="container">
          <div className="row">
        <div className="col-md-12">  
          <div className="title-section text-center">
            <h1 className="title faq-title">Faq's</h1>
          </div>         
        </div>
      </div>
        <div id="accordion" className='faq'>
        {
          faqData.map((item, i)=>{
            return   <div className="card">
    <div className="card-header" id={item.card_header_id}>
      <h5 className="mb-0">
        <a href="!#" className="btn btn-link" data-toggle="collapse" data-target={`#${item.data_target}`} aria-expanded={item.aria_expanded} aria-controls={item.aria_controls}>
         {item.ques}
        </a>
      </h5>
    </div>
    
    <div id={item.body_id} className={`collapse ${item.body_class}`} aria-labelledby={item.aria_labelledby} data-parent="#accordion">
      <div className="card-body">
      {item.ans}
      </div>
    </div>
  </div>
          })
        }

</div>
  </div>
        </section>
        
             <Footer/>
           </Fragment>
        )
    }
}

export default Home