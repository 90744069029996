import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class InternationalBusinessAdvisory extends Component {
    render() {
      document.title = 'SMS : : Services - business-advisory - International Business Advisory';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - business-advisory - International Business Advisory';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - business-advisory - International Business Advisory';
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">International Business Advisory</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/business-advisory">Business Advisory</Link></li> <li>International Business Advisory</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">International Business Advisory</h1>
              </div> <p>
                We always work for the management of the growing companies in the form of preparing clear and informed business advice. We can also give the support that will be giving them confidence in the future and allowing them to concentrate on the achievement of the business objectives. That said, we can give the improvement of the performance while controlling the business and reducing the cost, and reducing the risk. </p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/International-business-advisory.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h1 className="title">The service that we provide-</h1>
            <h3 className="title">Startup support</h3>
            <p>We can give a realistic approach that will be the best experience in terms of financial and administrative assistance. We can help you make the right choices that are applicable. The goal we hold is to focus on getting the business up and running in a safe way. Besides, we also make use of the proper technology in giving you personal and business goals.</p>
            <h3 className="title">Budgeting and forecasting</h3>
            <p>We can provide support to your team for meeting their expectations or even react in the event of something that is unexpected. We work with a team of experts who will always be collaborating with you to formulate the model that can play a part in the determination of the success of the potential change of direction.</p>
            <h3 className="title">Accounting and reporting solutions</h3>
            <p>No matter what is the size of the company, we can provide you with the key solutions that will be utilizing the high-end accounting software for the growth of your company. That said, we can always foster progression work with the platforms for Network and cloud based approach. We also have software partners who can ensure giving you the benefit in the fast-changing environment.</p>
            <h3 className="title">Emergency support</h3>
            <p>In case you look for short-term support with the requirement of additional expertise on the particular project, then it's worth considering our professionals. We can always keep in touch with the business health and review it for giving the streamlined businesses fetching you immense benefits in the long run.</p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default InternationalBusinessAdvisory