import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const OurServicesSingle = ({ data }) => {
  return (
    <div className="col-lg-4">
    <Link onClick={() => {window.location.href=`${data.link}`}}>
      <div className="iconbox iconleft style2">
        <div className="box-header">
          <div className="box-icon">
            <i className={ data.icon } />
          </div>
        </div>
        <div className="box-content">
          <h5 className="box-title">{ data.title } </h5>  
          <p>{ data.subtitle }</p> 
        </div>
      </div>
    </Link>
  </div>
  );
};

OurServicesSingle.propTypes = {
  data: PropTypes.object,
  spaceBottomClass: PropTypes.string
};

export default OurServicesSingle;
