import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class IncomeTaxReturnPreparation extends Component {
    render() {
      document.title = 'SMS : : Services - Tax-Services - Income Tax Return Preparation';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - Tax-Services - Income Tax Return Preparation';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - Tax-Services - Income Tax Return Preparation';
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Income Tax Return Preparation</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/tax-services">Tax Services</Link></li> <li>Income Tax Return Preparation</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Income Tax Return Preparation</h1>
              </div>
              <p>If you want the income tax return preparation services who will be working for your company, you can get our specialist team's assistance who can work for the amazing range of benefits. Whether you own a public trading company or a private enterprise, you can get these professionals by your side. We will be helping in the maintenance of the highest standards of transparency related to tax accounting policies. We can also work on the financial statements. Our professionals can always assist in the business by navigating the intricate complexities related to tax accounting.</p>
              <p>We can help give the solutions for all types of problems that would otherwise permanently damage your company's credibility. It's a well-known fact that accounting for income taxes becomes one of the most challenging areas in financial reporting. That said, for working properly, there is a need for recognition of the internal control deficiencies, material weaknesses, as well as financial statements. We can help in eradicating the complicating matters and the issues surrounding tax accounting.</p>     
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/Income-Tax-Return-Preparation.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h3 className="title">The Dedicated Approach in The Work</h3>
            <p>Our professionals will ensure working the best for accounting for the Income-Tax necessities and will set a comprehensive understanding of the global income tax effect. We can eradicate the company's points that might otherwise encounter problematic effects. We can also ensure quickly processing enormous amounts of data by taking minimal time. Our professionals can always give you the amazing assistance of the dedicated person who is observing the best interest by providing the expertise required to meet the challenges.</p>
            <h3 className="title">The Specialty with Them </h3>
            <p />You can rest assured about getting accurate data management in time. We can go ahead with the creation of the system that will ensure giving the amazing quickly and efficient detailing. We can also provide you with Income Tax analysis down to the code basics of Credit, Debit, and disclosure. We will always work for the management of indirect taxes. We understand that it is quite a challenging task and always requires expert advice for the navigation to the tax structure's complicated maze.<p />
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default IncomeTaxReturnPreparation