import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class BusinessFinancialStructures extends Component {
    render() {
      document.title = 'SMS : : Services - business-advisory - Business financial structures';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - business-advisory - Business financial structures';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - business-advisory - Business financial structures';
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Business and Financial Structures</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link  onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link  onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/business-advisory">Business Advisory</Link></li> <li>Business and Financial Structures</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Business and Financial Structures</h1>
              </div>
              <p>
                A business shines on the basis of financial structure. It becomes essential to consider certain points. Though you may underestimate the right business structure or the financial norms yet in the long-term, you will have to include them for setting the goals. For ensuring that the business is structured for succeeding, we can give the business growth profit margins. In work, we consider the current position and the goals while moving forward.   
              </p>
              <p>  We can provide the assistance of our tax specialist who will be working on the personal financial position and will be recommending the structures for growth of the private wealth and providing asset protection. We have small business tax professionals who can believe in taking your business success to the next level.</p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/business-and-financial-structures.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h1 className="title">Close monitoring of the transaction points</h1>
            <p>After the transaction processing, we can always work to develop the reports. We will jot down the points to lead your business to success. In work, we also pay attention to the spending effectiveness, burn rates, sales growth trends, customer and product/service profitability, and margin performance.</p>
            <p>We can provide you with the finance and accounting services available at the highly competitive prices. With us, you will get the availability of full-time employees who can work on the project. You can also get the hourly rates to project pricing and tax returns. We have the plans that will be working for the type of task that you are assigned and will also ensure the complexity with the resources required.</p> 
            <p>The financial structure that we will create for your company will be beneficial for setting the long-term good. We are the advanced professionals who can create the set of rules that will be helping in determining the order and values while entering the figures in the financial terms. </p>
            <p>We can give the valuation of the financial transaction records in the books of the account like the purchase sales. The computerized accounting system that we use helps in the preparation of the budget or also the income-expenditure report. We can also consider the profit and loss statement with the trial balances while making the statutory returns as permitted by law.</p>
          </div>
        </div>
      </section>
       
             <Footer/>
           </Fragment>
        )
    }
}

export default BusinessFinancialStructures