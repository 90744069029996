
import PropTypes from "prop-types";
import React,{useEffect, useState} from "react";
import teamData from "../data/our-team.json";
import axios from 'axios';
import MeetOurTeamSingle from "../components/pages/single/MeetOurTeamSingle";
import { API_URL } from "../config";
const MeetOurTeam = () => {

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async ()=>{
    const res = await axios.get(`${API_URL}/api/team/all`);
    if(res && res.data){
      if(res.data.status){
        setData(res.data.teamMembers)
        setLoading(false);
      }
    }
    
  }
  useEffect(() => {
    getData();
  },[]);
  return (
    <section className="flat-row  page-teammember2 padding2 bg-section">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="title-section text-center">
            <h1 className="title">Meet Our Team</h1>
            <div className="sub-title">
              We are here to Acelerate your business and help you find the way.
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* SLIDE 1 */}
        {
          isLoading ? "Data loading..." : <>
            {
              data.map((single, key) => {
              return (
                <MeetOurTeamSingle
                  data={single}
                  key={key}
                  spaceBottomClass="mb-30"
                />
              );
            })
            }
          </>
        }
         
            </div>
        </div>
      </section>
  );
};

MeetOurTeam.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default MeetOurTeam;
