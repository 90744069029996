import React,  { Component, Fragment } from 'react';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import TaxServices from '../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class BookkeepingServices extends Component {
    render() {
      document.title = 'SMS : : Services - Bookkeeping Services';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - Bookkeeping Services';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - Bookkeeping Services';
        return (
           <Fragment>
            <Header />
                      {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Bookkeeping Services</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li>Bookkeeping Services</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-section style3 left">
                <h1 className="title">Bookkeeping Services</h1>
              </div>
            </div>
            <div className="col-12 paratext">
              <p>
                We are specialised in offering the bookkeeping services that are fit for specific businesses and industries. In our work, we always use the advanced experience in the implementations of the thousands of the business bookkeeping service. We always give the unique approach in terms of bookkeeping for business, budget processes, goals, and everything else. In work, we always use the software that will be giving you the unrivalled experience. The expert business bookkeeping services that we provide can let you stay organised, prepared, and forecast for the future. We also have our service team who can provide insight for solutions to face the financial system challenges.
              </p>
            </div>
          </div>
          <div className="row rowLine">
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">1</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content opened">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Transactional activities</h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>	
                            We can record the transaction and data entry invoices, credit cards, bank transactions, payroll transactions, and more than that.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">2</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Control level services</h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>	
                            We can give the bookkeeping services for the closing entries, stop-down budgeting review, financial client work, supervision adjustment entry, preparation of financial terms, statement preparation, and more than that.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">3</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Accounting system implementation </h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>
                            We can offer the bookkeeping services for the accounting software selection as well as implementation. In work, we also utilise automated data entry and data conversion services. The integration with the third-party applications local and remote application hosting also stands out.
                          </p> 
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">4</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Reconciliation reporting services </h5>  
                        <div className="content">
                          <p>
                            We can give the reporting services for the bank account, customer invoices, credit cards collections, payments, vendor invoices, payroll, and more than that.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">5</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Activities Management services</h5>  
                        <div className="content">
                          <p> We can give the service for the bill pay, payroll processing, invoicing accounts, payable management, account receivable management, and more than that.</p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <br />
          <p> We can give a range of support for saving time and money. With our bookkeeping services, you can get the opportunity of paying for what you need. Whether you require the full-time expertise in the specific area or other services for an hourly basis, we can provide you with the solutions. You can scale the services quickly with end-to-end support. We can give a range of services and instant access to individuals. </p>
          <p>We can also ensure business continuity with our work. We are a reliable company who can work for your bookkeeping. So if you want the optimised system that will be functioning as the backbone of your success, this is the time to hire us. We always strive for maximising the potential. We can complimentary analysis of the system so that you can get the assessment of how good it is working for you.</p> 
        </div>
      </section>
       
             <Footer/>
           </Fragment>
        )
    }
}

export default BookkeepingServices