
import React, { Fragment } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { Formik, Field } from 'formik';
import AboutBanner from '../../assets/images/parallax/about-us.jpg';
import axios from 'axios';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { API_URL } from '../../config';
const MakeAppointment = () => {
    document.title = 'SMS : : Make an Appointment';
    document.getElementsByTagName("META")[3].content = 'SMS : : Make an Appointment';
    document.getElementsByTagName("META")[4].content = 'SMS : : Make an Appointment';


    const handleFormSubmit = async (values, { resetForm }) =>{
    
    const res = await axios.post(`${API_URL}/api/make-appointment`, values);
    if(res && res.data){
     
        if(res.data.status){
            toast.success(res.data.message, {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });

                resetForm();
        }else{
            toast.error(res.data.message, {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
       
    }
    
    }
    return (
        <Fragment>
            <Header />
            {/* Page title */}
            <div className="page-title parallax" style={{ backgroundImage: `url(${AboutBanner})` }}>
                <div className="section-overlay" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading">
                                <h1 className="title">Make an Appointment</h1>
                            </div>{/* /.page-title-captions */}
                            <div className="breadcrumbs">
                                <ul>
                                    <li className="home"><i className="fa fa-home" /><Link onClick={() => { window.location.href = "/" }}>Home</Link></li>
                                    <li>Make an Appointment</li>
                                </ul>
                            </div>{/* /.breadcrumbs */}
                        </div>{/* /.col-md-12 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </div>{/* /.page-title */}
            <section className='appointment-page'>

                <div className="container">
                    <div className='form-wrapper'>
                        <h3>MAKE AN APPOINTMENT</h3>
                        <p>Please fill in the information requested below. We will confirm your appointment as soon as possible, by</p>

                        <Formik
                            initialValues={{ fname: '', lname: '', email: '', mobile_number: '', fax: '', location: '', message: '', interest: [] }}
                            validate={values => {
                                const errors = {};
                                if (!values.fname) {
                                    errors.fname = 'First name is required !';
                                }
                                if (!values.lname) {
                                    errors.lname = 'Last name is required !';
                                }
                                if (!values.email) {
                                    errors.email = 'Email is required.';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = 'Invalid email address';
                                }
                                if (!values.mobile_number) {
                                    errors.mobile_number = 'Mobile number is required !';
                                }
                                if (!values.fax) {
                                    errors.fax = 'Fax is required !';
                                }
                                if (!values.location) {
                                    errors.location = 'Location is required !';
                                }
                                if (!values.message) {
                                    errors.message = 'Message is required !';
                                }
                                if (values.interest.length === 0) {
                                    errors.interest = 'At least one is selected !';
                                }
                                
                                
                                return errors;
                            }}
                            onSubmit={ handleFormSubmit }
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (<form onSubmit={handleSubmit}>
                                <div className=' row'>
                                    <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="text" placeholder='First Name' className='form-control'
                                            name="fname"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.fname}
                                        />
                                         <span className='help-error'>{errors.fname && touched.fname && errors.fname}</span>
                                         </div>
                                    </div>
                                    <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="text" placeholder='Last Name' className='form-control'
                                            name="lname"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lname}
                                        />
                                        <span className='help-error'>{errors.lname && touched.lname && errors.lname}</span>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="text" placeholder='Email' className='form-control'
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        <span className='help-error'>{errors.email && touched.email && errors.email}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="text" placeholder='Mobile Number' className='form-control'
                                            name="mobile_number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.mobile_number}
                                        />
                                        <span className='help-error'>{errors.mobile_number && touched.mobile_number && errors.mobile_number}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="text" placeholder='Fax' className='form-control'
                                            name="fax"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.fax}
                                        />
                                        <span className='help-error'>{errors.fax && touched.fax && errors.fax}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                    <div className="form-group">
                                        <label>Select your closest location</label>
                                        <select className='form-control'
                                            name="location"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.location}
                                        >
                                            <option value="">Select Locations</option>
                                            <option value="Oakleigh">Oakleigh</option>
                                            <option value="Dandenong">Dandenong</option>
                                            <option value="Wallace Ave">Wallace Ave</option>
                                            <option value="Tasmania">Tasmania</option>
                                        </select>
                                        <span className='help-error'>{errors.location && touched.location && errors.location}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <label>Please select from below to assist us in the nature of your interest</label><br/>
                                        <span className='help-error'>{errors.interest && touched.interest && errors.interest}</span>
                                    </div>


                                    <div className='col-md-6'>
                                   
                                    <div className="form-group">
                                        <label className='check'>
                                            <Field type="checkbox" name="interest" value="Tax Returns" />
                                            Tax Returns
                                        </label>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                    <div className="form-group">
                                        <label className='check'>
                                            <Field type="checkbox" name="interest" value="BAS Returns" />
                                            BAS Returns
                                        </label>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                    <div className="form-group">
                                        <label className='check'>
                                            <Field type="checkbox" name="interest" value="Financial Planning" />
                                            Financial Planning
                                        </label>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                    <div className="form-group">
                                        <label className='check'>
                                            <Field type="checkbox" name="interest" value="Company Set Up" />
                                            Company Set Up
                                        </label>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                    <div className="form-group">
                                        <label className='check'>
                                            <Field type="checkbox" name="interest" value="Book Keeping" />
                                            Book Keeping
                                        </label>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                    <div className="form-group">
                                        <label className='check'>
                                            <Field type="checkbox" name="interest" value="Others" />
                                            Others
                                        </label>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                    <div className="form-group">
                                        <label>Additional comments or Information</label>
                                        <textarea className='form-control' placeholder='Message' name="message"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.message}>
                                        </textarea>
                                        <span className='help-error'>{errors.message && touched.message && errors.message}</span>
                                        </div>
                                    </div>

                                    <div className='col-md-12 text-center'>
                                        <button type='submit' className=''>Submit</button>
                                    </div>

                                </div>

                            </form>)}
                        </Formik>
                    </div>

                </div>


            </section>
            <Footer />
        </Fragment>)
};

export default MakeAppointment;
