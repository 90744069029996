import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const WhyChoogeUsSingle = ({ data }) => {
  return (
    <li>
              <div className="iconbox effect text-center">
                <div className="box-header">
                  <div className="box-icon">
                    <i className={data.icon} />
                  </div>
                </div>
                <div className="box-content">
                  <h5 className="box-title">{data.title}</h5>  
                  <p>{data.subtitle}</p> 
                </div>
                <div className="effecthover" />
              </div>
            </li>
  );
};

WhyChoogeUsSingle.propTypes = {
  data: PropTypes.object,
  spaceBottomClass: PropTypes.string
};

export default WhyChoogeUsSingle;
