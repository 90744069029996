import React,  { Component, Fragment } from 'react';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import TaxServices from '../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";
class BusinessAdvisory extends Component {

    render() {
      document.title = 'SMS : : Services - Business Advisory';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - Business Advisory';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - Business Advisory';
        return (
           <Fragment>
            <Header />
                      {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Business Advisory</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li>Business Advisory</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-section style3 left">
                  <h1 className="title">Business Advisory </h1>
                </div>
              </div>       
              <div className="col-12 paratext">
                <p>
                  We can provide you with certified bookkeeping services that can be helpful for the growth of the business. We can offer sound bookkeeping solutions weekly, daily, and sale updates regarding the streamlined business operation. The profitable and time-saving option makes us the best. We are the partners who can give you the proper budget analysis for the cash flow forecasting and the extensive range of financial services that will be stimulating growth. So, you can rest assured about the audit support tax planning, tax fillings, and returns when you have us by your side.
                </p>
                <h2 className="h2Heading">The business advisory covers the following services:
                </h2>
                <p>
                  We can take the business advisory to the next level. With us, you can rest assured that the solution will be helping in decreased delivery cost and enhanced support. 
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="iconbox style3 services-more box-shadow2 iconTax2">
                  <div className="d-flex align-items-center customServices">
                    <div className="p-2 flex-grow-1">
                      <div className="box-content">
                        <h5 className="box-title">Business and financial structures </h5>  
                        <p>We are the number one solution for the business because you can get the high-end service for scalable financial structure planning.</p> 
                      </div>
                    </div>
                    <div className="p-2 align-self-center">
                      <Link className="readmore" to="/services/business-advisory-service/business-and-financial-structures">Read More <i className="fa fa-angle-right" /></Link>
                    </div>
                  </div>
                </div>     
              </div>
              <div className="col-lg-6">
                <div className="iconbox style3 services-more box-shadow2 iconTax2">
                  <div className="d-flex align-items-center customServices">
                    <div className="p-2 flex-grow-1">
                      <div className="box-content">
                        <h5 className="box-title">Business Buying and selling </h5>  
                        <p>Our accounting services are tremendous and ensure performance. We can help in transforming accountants into the rainmaker.</p> 
                      </div>
                    </div>
                    <div className="p-2 align-self-center">
                      <Link className="readmore" to="/services/business-advisory-service/business-buying-and-selling">Read More <i className="fa fa-angle-right" /></Link>
                    </div>
                  </div>
                </div>     
              </div>
              <div className="col-lg-6">
                <div className="iconbox style3 services-more box-shadow2 iconTax2">
                  <div className="d-flex align-items-center customServices">
                    <div className="p-2 flex-grow-1">
                      <div className="box-content">
                        <h5 className="box-title">Cash flow forecasting</h5>  
                        <p>Suppose you are quite worried about the cash flow forecast service and do not know how to balance the financial goals.</p> 
                      </div>
                    </div>
                    <div className="p-2 align-self-center">
                      <Link className="readmore" to="/services/business-advisory-service/cash-flow-forecasting">Read More <i className="fa fa-angle-right" /></Link>
                    </div>
                  </div>
                </div>     
              </div>
              <div className="col-lg-6">
                <div className="iconbox style3 services-more box-shadow2 iconTax2">
                  <div className="d-flex align-items-center customServices">
                    <div className="p-2 flex-grow-1">
                      <div className="box-content">
                        <h5 className="box-title">Outsourced accounting solutions</h5>  
                        <p>We can give the outsourced bookkeeping services applicable to CPA firms, small and medium businesses, and startups. </p> 
                      </div>
                    </div>
                    <div className="p-2 align-self-center">
                      <Link className="readmore" to="/services/business-advisory-service/outsourced-accounting-solutions">Read More <i className="fa fa-angle-right" /></Link>
                    </div>
                  </div>
                </div>     
              </div>
              <div className="col-lg-6">
                <div className="iconbox style3 services-more box-shadow2 iconTax2">
                  <div className="d-flex align-items-center customServices">
                    <div className="p-2 flex-grow-1">
                      <div className="box-content">
                        <h5 className="box-title">Strategic planning </h5>  
                        <p>We understand the pressures of daily business life, so we undertake strategic planning for a successful business.</p> 
                      </div>
                    </div>
                    <div className="p-2 align-self-center">
                      <Link className="readmore" to="/services/business-advisory-service/strategic-planning">Read More <i className="fa fa-angle-right" /></Link>
                    </div>
                  </div>
                </div>      
              </div>
              <div className="col-lg-6">
                <div className="iconbox style3 services-more box-shadow2 iconTax2">
                  <div className="d-flex align-items-center customServices">
                    <div className="p-2 flex-grow-1">
                      <div className="box-content">
                        <h5 className="box-title">Corporate governance</h5>  
                        <p>Over the years, we have excelled in the accountancy services and have been associated with plenty of startups in the region.</p> 
                      </div>
                    </div>
                    <div className="p-2 align-self-center">
                      <Link className="readmore" to="/services/business-advisory-service/corporate-governance">Read More <i className="fa fa-angle-right" /></Link>
                    </div>
                  </div>
                </div>     
              </div>
              <div className="col-lg-6">
                <div className="iconbox style3 services-more box-shadow2 iconTax2">
                  <div className="d-flex align-items-center customServices">
                    <div className="p-2 flex-grow-1">
                      <div className="box-content">
                        <h5 className="box-title">International business advisory </h5>  
                        <p>Qualified financial advisors who can give to the social and business advisory ensure the proper management of the cash flow and every other aspect of the company</p> 
                      </div>
                    </div>
                    <div className="p-2 align-self-center">
                      <Link className="readmore" to="/services/business-advisory-service/international-business-advisory">Read More <i className="fa fa-angle-right" /></Link>
                    </div>
                  </div>
                </div>     
              </div>
              <div className="col-lg-6">
                <div className="iconbox style3 services-more box-shadow2 iconTax2">
                  <div className="d-flex align-items-center customServices">
                    <div className="p-2 flex-grow-1">
                      <div className="box-content">
                        <h5 className="box-title">Succession planning</h5>  
                        <p>We can provide you with the succession planning that will be good enough to identify and develop the new leaders.</p> 
                      </div>
                    </div>
                    <div className="p-2 align-self-center">
                      <Link className="readmore" to="/services/business-advisory-service/succession-planning">Read More <i className="fa fa-angle-right" /></Link>
                    </div>
                  </div>
                </div> 
              </div>
              <div className="col-lg-6">
                <div className="iconbox style3 services-more box-shadow2 iconTax2">
                  <div className="d-flex align-items-center customServices">
                    <div className="p-2 flex-grow-1">
                      <div className="box-content">
                        <h5 className="box-title">Performance improvement</h5>  
                        <p>We can keep the performance improvement practice that will help in unlocking profitability by driving revenue.</p> 
                      </div>
                    </div>
                    <div className="p-2 align-self-center">
                      <Link className="readmore" to="/services/business-advisory-service/performance-improvement">Read More <i className="fa fa-angle-right" /></Link>
                    </div>
                  </div>
                </div>     
              </div>
              <div className="col-lg-6">
                <div className="iconbox style3 services-more box-shadow2 iconTax2">
                  <div className="d-flex align-items-center customServices">
                    <div className="p-2 flex-grow-1">
                      <div className="box-content">
                        <h5 className="box-title">Accounting and taxation compliance</h5>  
                        <p>We can provide you with accounting services and undertake programs with the super fund transaction's post and processing.</p> 
                      </div>
                    </div>
                    <div className="p-2 align-self-center">
                      <Link className="readmore" to="/services/business-advisory-service/accounting-and-taxation-compliance">Read More <i className="fa fa-angle-right" /></Link>
                    </div>
                  </div>
                </div>     
              </div>
              <div className="col-lg-12 col-12 paratext">
                <p>With all our processes, we can provide you the benefit of focusing on core areas and staying competitive. We can also help remove the in-house staffing barriers for bringing down the operating cost in the right way. You can rest assured about getting this field resources and professionals on-demand that will offer flexibility in the scaling operations.</p>
              </div>
            </div>  
          </div>
        </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default BusinessAdvisory