import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class CashFlowForecasting extends Component {
    render() {
      document.title = 'SMS : : Services - business-advisory - Cash flow forecasting';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - business-advisory - Cash flow forecasting';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - business-advisory - Cash flow forecasting';
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Cash Flow Forecasting</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link  onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/business-advisory">Business Advisory</Link></li> <li>Cash Flow Forecasting</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Cash Flow Forecasting</h1>
              </div>
              <p>
                Cash flow forecasting works as an integral part of financial management. That said, with our work, we can allow better cash flow will be well into the fiscal year. We can provide you with the summarized concept of the receivables and deliverables. We also consider the historical and projected data to be incorporated. The best part of our service is the availability of the cash flow forecasts as an accurate list. 
              </p>
              <p>We will eradicate the presence of poor resources and lack of communication. Reliability of cash flow forecasting with a deep understanding of company financials makes us the best. With the years of experience, precise reporting and business success, you can rest assured about getting the security of living by the numbers.</p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/cash-flow-forecasting.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h1 className="title">A clear glimpse of your projected income and expenses</h1>
            <p>With our reliable professionals, we can enable the attainment of financial decisions and goals. Even if you want the estimation of the annual profits, calculation of the end-of-year debt, or determination of necessary investment or loan steps, you can rest assured that we will work with the experience and connections to help fuel the decision-making process.
            </p><h1 className="title">The entities that we consider</h1>
            <p>We can ensure consideration of the estimated income, loan, sales, rebates, refunds, grants, fees, royalties, expenses, purchases, loan repayment, fees, payroll, necessary steps, as well as investment.</p>
            <p>We understand the points related to the most important aspect of any ongoing business. Taking that into consideration, we can implement the full cash cycle. It can also come inclusive of vendor bill payments, customer invoicing, payment processing, and cash flow reporting. We can also work with the receivables and coordinate the factoring process.</p>
            <p>For getting the enhanced idea about the same, you can book an appointment with our professionals today and get the approach selected that will ensure better cash flow without hindrances.</p> 
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default CashFlowForecasting