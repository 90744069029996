import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class CorporateGovernance extends Component {
    render() {
      document.title = 'SMS : : Services - business-advisory - Corporate governance';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - business-advisory - Corporate governance';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - business-advisory - Corporate governance';
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Corporate Governance</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/business-advisory">Business Advisory</Link></li> <li>Corporate Governance</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Corporate Governance </h1>
              </div>
              <p>Corporate governance proves to be highly effective and efficient for running the organization and will be of supporting the objective of transparency and accountability. That said, our professionals always consider good corporate governance to contribute towards improved performance. We are good governance systems and reporting structure professionals who can work for the vital part of the business. </p>
              <p>We have the specialist corporate governance team who will be assisting you in the integrity of the relevant best practices into the governance and the reporting systems. We can always work for the understanding of the requirements and also carefully matching with them for providing the board audit committee and the senior management. We always utilize the tools that will be ensuring governance effectively. We have an understanding of the requirements that will be carefully matching with you for giving you the support the way you want.</p> 
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/Corporate-Governance.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h1 className="title">The special role of our professionals</h1>
            <p>Our team will always be selecting the compliance statements in work. We are built on the mission of supporting the transmission that can help in complying with the requirements and the tagging of the financial statements and the corporation tax returns. We can also review the internal audit function and the assessment of the risk and compliance function.</p>
            <h1 className="title">The customized succession plan for your benefits </h1>
            <p>We have the professionals who can give you the special service in assisting the creation process of the succession plan. With our work, we will also be ensuring influence in the business and also hold the key executives for ensuring the transfer of the corporate governance to the future generations. Our team ensures working with the lead management structure for giving the face to the company. We can also identify the related principles that will lead to the planned creation of the roadmap for the process of the transfer of the ownership.</p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default CorporateGovernance