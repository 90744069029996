import PropTypes from "prop-types";
import React from "react";
import testimonialData from "../data/testimonial.json";
import TestimonialSingle from "../components/pages/single/TestimonialSingle";

const Testimonial = () => {
  return (
    <section className="flat-row section-testimonials bg-section">
    <div className="container">
      <div className="row">
        <div className="col-md-12">  
          <div className="title-section text-center">
            <h1 className="title">Testimonials</h1>
            <div className="symbol">
              ​‌“ 
            </div>
          </div> 
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
        <div className="flat-testimonials2" data-item={2} data-nav="false" data-dots="true" data-auto="false">
        {/* SLIDE 1 */}
          {testimonialData &&
            testimonialData.map((single, key) => {
              return (
                <TestimonialSingle
                  data={single}
                  key={key}
                  spaceBottomClass="mb-30"
                />
              );
            })}
            </div>
            </div>
            </div>
          </div>      
        </section>
  );
};

Testimonial.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default Testimonial;
