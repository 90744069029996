import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const LatestBlogSingle = ({ data }) => {
  return (
    <article className="entry border-shadow  clearfix">
    <div className="entry-border clearfix">
      <div className="featured-post">
        <Link to={`/blogs/${data.slug}`}> <img src={data.thumbnail} alt="blog_thumb" /></Link>
      </div>{/* /.feature-post */}
      <div className="content-post">
        {/* <span className="category">{ data.category }</span> */}
        <h2 className="title-post"> <Link to={`/blogs/${data.slug}`}>{ data.title }</Link></h2>
        <div className="meta-data style2 clearfix">
          <ul className="meta-post clearfix">
            <li className="day-time">
              <span>{ data.post_date }</span>
            </li>
          </ul>
        </div>
      </div>{/* /.contetn-post */}
    </div>{/* /.entry-border */}
  </article>
  );
};

LatestBlogSingle.propTypes = {
  data: PropTypes.object,
  spaceBottomClass: PropTypes.string
};

export default LatestBlogSingle;
