import React, { Fragment, useEffect, useState } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import AboutBanner from '../../assets/images/parallax/about-us.jpg';
import { Link } from "react-router-dom";
import axios from 'axios';
import {API_URL} from '../../config'
const Blog = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async ()=>{
    const res = await axios.get(`${API_URL}/api/posts`);
    if(res && res.data){
      if(res.data.status){
        setData(res.data.posts)
        setLoading(false);
      }
    }
    
  }
  useEffect(() => {
    getData();
  });
  document.title = 'SMS : : Blog';
  document.getElementsByTagName("META")[3].content = 'SMS : : Blog';
  document.getElementsByTagName("META")[4].content = 'SMS : : Blog';
  return (
    <Fragment>
      <Header />
      {/* Page title */}
      <div className="page-title parallax" style={{ backgroundImage: `url(${AboutBanner})` }}>
        <div className="section-overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading">
                <h1 className="title">Blog</h1>
              </div>{/* /.page-title-captions */}
              <div className="breadcrumbs">
                <ul>
                  <li className="home"><i className="fa fa-home" /><Link onClick={() => { window.location.href = "/" }}>Home</Link></li>
                  <li>Blog</li>
                </ul>
              </div>{/* /.breadcrumbs */}
            </div>{/* /.col-md-12 */}
          </div>{/* /.row */}
        </div>{/* /.container */}
      </div>{/* /.page-title */}
      <section className="blog-page">
        <div className="container">
          <div className='row'>
          {
          isLoading ? <div className="container"><p>Data loading...</p></div> : <>
            {
              data.map((single, i) => {
              return (
                <div className='col-md-4' key={i}>
              <article className=" border-shadow  clearfix">
                <div className="entry-border clearfix">
                  <div className="featured-post">
                    <Link to={`/blogs/${single.slug}`}> <img src={single.thumbnail} alt="blog_thumb" /></Link>
                  </div>{/* /.feature-post */}
                  <div className="content-post">
                    <span className="category">{single.category.title}</span>
                    <h2 className="title-post"> <Link to={`/blogs/${single.slug}`}>{single.title}</Link></h2>
                    <div className="meta-data style2 clearfix">
                      <ul className="meta-post clearfix">
                        <li className="day-time">
                          <span>{single.post_date}</span>
                        </li>
                      </ul>
                    </div>
                  </div>{/* /.contetn-post */}
                </div>{/* /.entry-border */}
              </article>
            </div>
              );
            })
            }
          </>
        }

          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  )
};

export default Blog;
