import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class GoodsServicesTax extends Component {
    render() {
      document.title = 'SMS : : Services - Tax-Services - Goods Services Tax';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - Tax-Services - Goods Services Tax';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - Tax-Services - Goods Services Tax';
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Goods and Services Tax (GST)</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/tax-services">Tax Services</Link></li> <li>Goods and Services Tax (GST)</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Goods and Services Tax (GST) 
                </h1>
              </div>
              <p> We always strive to provide you with accurate accounting and timely record keeping. We understand the importance of GST accounting for your firm and also how problematic it can be for your employees to keep track of the same. For ensuring successful business operations, we can work on the outsourced job. Whether you are holding a medium or large-sized business, we can always ensure keeping the accurate track of your records—these records for the GST consist of the past and current operating transactions.</p>
              <h3 className="title">Timely Record Keeping For Your Services</h3>
              <p>With us, you can get assistance in the form of timely and accurate data-keeping. We always take precision in the maintenance of financial information and are very supportive in accurate decision making and profitable business operations. Accurate business information helps in identifying accurate costing. Our professionals also ensure working on the past financial information. Our professionals also ensure working with the highly useful entities to plan budgets, cost-cutting as well as upcoming expansion plans.</p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/gst.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="p-2  paratext2 ">
            <p>We can work as the Team of Expert Finance or Accounting Professionals who can ensure maximum client satisfaction. We also maintain the accuracy of financial information. With our work, we can make sure Clients would benefit from experience and expertise. The best part of our service is that we have been supporting clients and have earned a reputation that way. In regards to GST accounting, we can work for Financial Accounting and Bookkeeping Services.</p> 
            <p>Besides that, we also guarantee VAT Returns and VAT Audits. With us, you can get the VAT Advisory &amp; Compliance Services. We always assure you of providing the Financial Advisory and Strategic Decision-Making Services. With us, you can get Business Valuation Services. Besides, we also involve the expert team of finance professionals to assist you in the overall process. That said, we will eliminate any queries in case you require any information.</p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default GoodsServicesTax