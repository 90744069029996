import React,  { Component, Fragment } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import AboutBanner from '../../assets/images/parallax/about-us.jpg';
import {Link} from "react-router-dom";
import usefulLink from '../../data/usefulLink.json'
const UsefullLinks = () => {
  document.title = 'SMS : : Useful Links';
  document.getElementsByTagName("META")[3].content = 'SMS : : Useful Links';
  document.getElementsByTagName("META")[4].content = 'SMS : : Useful Links';
  return (
    <Fragment>
    <Header />
        {/* Page title */}
<div className="page-title parallax" style={{ backgroundImage: `url(${AboutBanner})` }}>
  <div className="section-overlay" />
  <div className="container">
    <div className="row">
      <div className="col-md-12"> 
        <div className="page-title-heading">
          <h1 className="title">Useful Links</h1>
        </div>{/* /.page-title-captions */}  
        <div className="breadcrumbs">
          <ul>
            <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
            <li>Useful Links</li>             
          </ul>                   
        </div>{/* /.breadcrumbs */} 
      </div>{/* /.col-md-12 */}  
    </div>{/* /.row */}  
  </div>{/* /.container */}                      
</div>{/* /.page-title */} 
<div className='container'>
<div className='useLinks'>
<p>Trying to find a high-quality and useful site on the web can often be a time consuming experience. To save you the trouble we have compiled a list of websites that we have found to be valuable sources of information. Clicking on the link will open a new window for you.</p>
<br/>
<div id="accordion" className='faq'>
        {
            usefulLink.map((item, i)=>{
            return   <div className="card" key={i}>
    <div className="card-header" id={item.card_header_id}>
      <h5 className="mb-0">
        <a href="!#" className="btn btn-link" data-toggle="collapse" data-target={`#${item.data_target}`} aria-expanded={item.aria_expanded} aria-controls={item.aria_controls}>
         {item.linkMain}
        </a>
      </h5>
    </div>
    
    <div id={item.body_id} className={`collapse ${item.body_class}`} aria-labelledby={item.aria_labelledby} data-parent="#accordion">
      <div className="card-body">
      <ul>
        
      </ul>
        {
            item.Links.map((item2, i)=>{
            return   <li>
              <a href={item2.url} target="_blank" rel="noreferrer nofollow">{item2.link_lable}</a>
          </li>
            })
        }
      </div>
    </div>
  </div>
          })
        }

</div>
<br/>
<p><strong>Note : </strong>The websites links provided on this page are for general interest only. We do not endorse any products and services offered by the websites listed on this page.</p>
</div>

</div>
 <Footer/>
</Fragment>
  )
}

export default UsefullLinks