import React,{Fragment} from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import AboutBanner from '../../assets/images/parallax/about-us.jpg';
import {Link} from "react-router-dom";
const PrivacyPolicy = () => {
  document.title = 'SMS : : Privacy Policy';
  document.getElementsByTagName("META")[3].content = 'SMS : : Privacy Policy';
  document.getElementsByTagName("META")[4].content = 'SMS : : Privacy Policy';
  return (
                <Fragment>
            <Header />
                {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${AboutBanner})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Privacy Policy</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li>Privacy Policy</li>             
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="abutus">
          <div className="container">
            <p>Our Commitment to Your Privacy: As a client of SMS Accounting Partners we are committed to ensuring the privacy of your information and we understand how important the privacy of your personal information is to you. With the introduction of the amendments to the Privacy Act 1988 we appointed a Privacy Officer to ensure our policies are adhered to. You can be assured, as a client of SMS Accounting Partners that we will make every reasonable effort to protect and keep confidential any information you provide to us.</p>
          </div>
          </section>
        <Footer/>
       </Fragment>
);
};

export default PrivacyPolicy;
