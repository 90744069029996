import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class TaxAuditPreparations extends Component {
    render() {
      document.title = 'SMS : : Services - Tax-Services - Tax Audit Preparations';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - Tax-Services - Tax Audit Preparations';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - Tax-Services - Tax Audit Preparations';
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Tax Audit Preparations and Advisory</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/tax-services">Tax Services</Link></li> <li>Tax Audit Preparations and Advisory</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Tax Audit Preparation and Advisory</h1>
              </div>
              <p>We are the expert professionals who can work for partnering with several companies that can work for incorporating tax-advantaged strategies in your financial plan. We can also work for Financial Planning, Estate Planning, Investment Management, Business Succession Planning, Retirement Planning, Employer-Sponsored Retirement Plans, Accounting, Tax, Audit, Advisory Services. We can also work with the accounting, tax, audit, and advisory services.</p>
              <h3 className="title">The Leveraged Service For The Tax Audit</h3>
              You can get leveraged accounting, tax, audit, as well as advisory services. We can also work with professionals to provide objective as well as accurate assessments of the current financial situation. We also hold the unique ability to include critical tax-efficient strategies. That said, we always excel with our services that are aimed at providing you with a more effective and comprehensive financial plan.
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/Tax-Audit.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h3 className="title">The Fleet of Services That We Offer : </h3>
            <p>With the availability of our new accounting system for your business, we can ensure making the current system more productive. Besides, our professionals always strive to turn the accounting data into an asset for your organization.</p>
            <p>With us, you can rest assured of getting proactive tax planning from a team. We are the experts who are quite knowledgeable about location-specific and industry-specific laws that will be quite relevant for your company. </p>
            <p> We also specialize in conducting audits for clients who own their businesses.  In this way, we can guarantee tremendous value to a business. </p>
            <p>With our work, we ensure assisting you with the identification of the opportunities to improve business, accounting, management,  and operational procedures.</p>
            <p>Besides the above-mentioned points, we ensure providing you with the advisory services to help you reassure investors. In the approach, we also guarantee working with the regulators and lenders through easy-to-understand, as well as audited financial reports. We also ensure valuable recommendations for improving accounting, management, business as well as operational procedure.</p>
          </div>           
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default TaxAuditPreparations