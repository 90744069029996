import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class SuccessionPlanning extends Component {
    render() {
      document.title = 'SMS : : Services - business-advisory - Succession Planning';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - business-advisory - Strategic Planning';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - business-advisory - Strategic Planning';
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Succession Planning</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/business-advisory">Business Advisory</Link></li> <li>Succession Planning</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
       
      <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Succession Planning</h1>
              </div> 
              <p>We can give the succession planning services. We are a team of experts who can draft the successful plan with the necessary documents and also administer the execution of the plans. We can take into consideration the tax implications of the strategy involved. Over the years, we have been serving plenty of clients and can remove all the complexities. The business succession that we hold is a trustworthy service because we always do it with expertise and knowledge that will make you feel secured.</p>
              <p>With our succession planning and the subsequent execution, we take pride in the designing of the Strategies and also devising a suitable succession plan. If you want to take a succession plan to the stepfather, you can book us.</p>
              <p>Whenever it comes to the determination of how to transfer the points within the business, we can provide the guidelines for the executors and trustees as well. We always maintain privacy and preserve the capital for future generations as well.</p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/succession-planning.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h1 className="title">Why work with us?</h1>
            <ul className="ser-list">
              <li>We are the professionals who can help our clients with the incorporation of the foundations related to the planning.</li>
              <li>We are trustworthy professionals who excel in the field. The services that we provide will give the managed structure s fit for removing all the obligations.</li>
              <li>The unrivaled independent Global team and footprint that we hold also makes us amazing. You can get the unrivaled multiple service offerings that will be getting created a unique solution for meeting the needs.</li>
              <li>We have dedicated client relationship management facilities that will be ensuring personal and specialized services to both the clients as well as professional advisers.</li>
              <li>The expertise that we have been holding over the years also makes us the best team experts in the field.</li>
            </ul>
            <p>With our work, we can assure you of freeing up your time to concentrate on core business activities. You can always outsource some of your day-to-day duties or more complex tasks to us while choosing the level of service you need.</p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default SuccessionPlanning