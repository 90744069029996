import React,  { Component, Fragment } from 'react';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import TaxServices from '../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class ComplianceServices extends Component {
    render() {
      document.title = 'SMS : : Services - Compliance Services';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - Compliance Services';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - Compliance Services';
        return (
           <Fragment>
            <Header />
                      {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Compliance Services</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li>Compliance Services</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-section style3 left">
                <h1 className="title">Compliance Services</h1>
              </div>
            </div>
            <div className="col-12 paratext">
              <p>We can give flexible accounting and finance functions that will keep you away from pressure. We can provide better reporting and compliance. Even if you’re looking for the services in a shorter time frame with fewer resources, we can cater to the needs.</p>
              <p> We can work as the top-notch accounting, finance and tax departments who won’t make you spend a disproportionate amount of time. We can give flexible routine compliance and compilation activities. We can do so even while focusing on the higher value activities like core business support, quality and risk management, strategic tax planning, and process and technology improvement.</p>
              <p> Tax Compliance Services that we provide comes with numerous support types. Have a look below. </p>
            </div>
          </div>
          <div className="row rowLine">
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">1</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content opened">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Declaration Services</h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>
                            We can provide a Reliable and qualified declaration and tax enforcement services. With our work, we will ensure a lasting and trusting client relationship. We can also provide you with Qualified structural recommendations. We have detailed knowledge of the client’s circumstances and needs. We always consider the metrics of the declaration services to work on an excellent basis for comprehensive business and legal and tax advisory. With our work, we can ensure long-term success. 
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">2</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Appeal/Tax Litigation</h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p> 
                            With our Tax Compliance Services, we can comply with all tax compliance obligations. That said, we support the process of preparing corporate and individual tax returns. We can also go ahead with all related preparation and subsequent work. Besides, corporate income tax, we also specialise in trade tax, VAT or income tax returns.</p>
                          <p>  Where necessary, we can assist with filing an objection against the tax assessments. We can also assist in taking legal action before the fiscal courts. 
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">3</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title"> Optimisation of Group Structure </h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>
                            We can provide the services even for the Tax Rate. We always take into consideration the analysis of the tax situation. We can also give favourable solutions for a group of companies. We can minimise the tax burden. In work, we consider fiscal units, transfer pricing or restructuring of group entities.
                          </p> 
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">4</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Tax Audit Support </h5>  
                        <div className="content">
                          <p>
                            In work, we accompany and support you in the discussion of tax issues. We can do so with the tax authorities and assist in the prevention of the incorrect tax assessments. We hold such specifications in the course of a tax audit.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">5</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Corporate income tax return </h5>  
                        <div className="content">
                          <p>
                            We can start working with the Corporate income tax compliance. We can give high-end assistance to comply with the tax obligation. That said, we can assist you with tax risk management. You can rest assured that we will assist in preparing (filing) corporate income tax returns. With us, you can get the Year-end closing services. We can give the specialised Assistance during audits and representation during tax inspections. Besides that, you can also be the Ongoing tax advice on normal and exceptional matters. We identify the points that may affect your business.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">6</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Value Added Tax Compliance (VAT Compliance)</h5>  
                        <div className="content">
                          <p>
                            We also specialise with our team of VAT professionals. We can help you out with matters associated with the VAT returns filing and compliance in our work. We follow these norms to comply with local statutory obligations. Besides, we also specialise with VAT compliance and advice. We can assist with the local and international transactions.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">7</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">VAT Reclaims</h5>  
                        <div className="content">
                          <p>
                            We can also assist with the refunds. We specialise with VAT registrations and the handling of communication. We can work closely with the (local) tax authorities.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">8</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">VAT Risk management and services</h5>  
                        <div className="content">
                          <p>In every way, we can ensure Dispute resolution and representation. We can do so during tax audits and tax inspections. </p> 
                          <p>Our services are a remarkable part of which we prepare a separate and uniform determination of profits and preliminary VAT returns. We also do so along with handling electronic submission to the tax authorities.</p> 
                          <p> With our services, you can rest assured that handling financial accounting will be the easiest. With our work, we can help offload burdens. You can rest assured that there will be no problem related to account reconciliation. We can also ensure utilisation of the modern process techniques. We can also ensure payroll accounting. We handle monthly payslips with our work, including determination of holiday allowances, calculation, registration, and certification of wage tax and social security contributions. </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default ComplianceServices