
import PropTypes from "prop-types";
import React,{useEffect, useState} from "react";
import axios from 'axios';
import LatestBlogSingle from "../components/pages/single/LatestBlogSingle";
import {API_URL} from '../config'
const LatestBlog = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async ()=>{
    const res = await axios.get(`${API_URL}/api/posts`);
    if(res && res.data){
      if(res.data.status){
        setData(res.data.posts)
        setLoading(false);
      }
    }
    
  }
  useEffect(() => {
    getData();
  });
  return (
    <section className="flat-row v6">
    <div className="container">
      <div className="row">
        <div className="col-md-12">  
          <div className="title-section text-center">
            <h1 className="title">Latest Blog</h1>
          </div>         
        </div>
      </div>
      <div className="blog-carosuel-wrap">
        <div className="blog-shortcode post-grid">
        {
          isLoading ? "Data loading..." : <>
            {
              data.map((single, key) => {
              return (
                <LatestBlogSingle
                  data={single}
                  key={key}
                  spaceBottomClass="mb-30"
                />
              );
            })
            }
          </>
        }
            </div>
            </div>
          </div>  
        </section>
  );
};

LatestBlog.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default LatestBlog;
