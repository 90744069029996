import React,  { Component, Fragment } from 'react';
import GoogleMapReact from 'google-map-react';
const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMap extends Component {
    static defaultProps = {
        center: {
          lat: 25.38,
          lng: 83.01
        },
        zoom: 11
      };
    render() {
        return (
            // Important! Always set the container height explicitly
      <div style={{ height: '533px', width: '100%' }}>
      <GoogleMapReact
       // bootstrapURLKeys={{ key: 'AIzaSyBtwK1Hd3iMGyF6ffJSRK7I_STNEXxPdcQ&region=GB' }}
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom}
      >
        <AnyReactComponent
          lat={25.386552557907894}
          lng={83.0198408979841}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
        )
    }
}

export default GoogleMap
