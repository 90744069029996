import React,  { Component, Fragment } from 'react';
import {Link, withRouter} from "react-router-dom";

import logo from '../../assets/images/logo3.png';


class Header extends Component {
  

    render() {
     
        return (
           <Fragment>
        
        <div className="boxed">
          <div className="top style4 background-661 padding-none">
            <div className="container">
              <div className="row">
                <div className="col-md-7 col-7">                      
                  <div className="wrap-top margin-top9 margin-left_2 clearfix">
                    <ul className="flat-information style2 float-left">
                      <li><i className="fa fa-envelope" /><a href="mailto:info@smstax.com.au">info@smstax.com.au</a></li>
                      <li><i className="fa fa-phone" /><a href="tel:03 9530 9750">03 9530 9750</a></li>
                    </ul>
                  </div>
                </div>{/* /.col-md-7 */}       
                <div className="col-md-5 col-5">
                  <div className="wrap-top reponsive-none767 clearfix">
                    <aside className="widget float-right reponsive-none margin-left29">
                      <div className="btn-click style2">
                        <Link to="/make-an-appointment" className="flat-button" >Book an appointment</Link>
                       
                      </div>
                    </aside>
                    <ul className="social-links color-white float-right margin-left25 margin-top14 flat-top">
                      <li><a href="https://www.facebook.com/SMSForTax" target="_blank" rel="noreferrer"><i className="fa fa-facebook" /></a></li>
                      <li><a href="https://twitter.com/smsaccounting1" target="_blank" rel="noreferrer"><i className="fa fa-twitter" /></a></li>
                      <li><a href="https://www.instagram.com/sms_accounting_partners/" target="_blank" rel="noreferrer"><i className="fa fa-instagram" /></a></li>
                    </ul>
                  </div>
                </div>        
              </div>{/* /.row */}
            </div>{/* /.container */}
          </div>{/* /.top */}
          <div className="flat-header-wrap">
            {/* Header */}            
            <header id="header" className="header header-style2 box-shadow1 bg-white header-classic">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-9 align-self-center">
                    <div className="brand">
                    <Link onClick={() => {window.location.href="/"}}>
                        <img src={logo} alt=""/>
                      </Link>
                    </div>{/* /.logo */}
                  </div>
                  <div className="col-lg-9 col-3">
                    <div className="flat-wrap-header">
                      <div className="nav-wrap clearfix">        
                        <nav id="mainnav" className="mainnav style2 color-661 float-right">
                          <ul className="menu"> 
                            <li className="active">
                            <Link onClick={() => {window.location.href="/"}}>Home</Link>
                            </li>
                            <li><a href="#">Services</a>
                              <ul className="submenu"> 
                                <li><Link to="/services/business-advisory">Business Advisory </Link>
                                  <ul className="submenu"> 
                                    <li><Link to="/services/business-advisory-service/business-and-financial-structures">Business and Financial Structures </Link></li>
                                    <li><Link to="/services/business-advisory-service/business-buying-and-selling">Business Buying and Selling</Link></li>
                                    <li><Link to="/services/business-advisory-service/cash-flow-forecasting">Cash Flow Forecasting</Link></li>
                                    <li><Link to="/services/business-advisory-service/outsourced-accounting-solutions">Outsourced Accounting Solutions</Link></li>
                                    <li><Link to="/services/business-advisory-service/strategic-planning">Strategic Planning </Link></li>
                                    <li><Link to="/services/business-advisory-service/corporate-governance">Corporate Governance</Link></li>
                                    <li><Link to="/services/business-advisory-service/international-business-advisory">International Business Advisory</Link></li>
                                    <li><Link to="/services/business-advisory-service/succession-planning">Succession planning</Link></li>
                                    <li><Link to="/services/business-advisory-service/performance-improvement">Performance improvement</Link></li>
                                    <li><Link to="/services/business-advisory-service/accounting-and-taxation-compliance">Accounting and Taxation compliance</Link></li>
                                  </ul>{/* /.submenu */}
                                </li>
                                <li><Link onClick={() => {window.location.href="/services/specialist-services"}}>Specialist Services</Link></li>
                                <li><Link to="/services/tax-services">Tax Services</Link>
                                  <ul className="submenu"> 
                                    <li><Link to="/services/tax-service/compliance-tax">Compliance Tax </Link></li>
                                    <li><Link to="/services/tax-service/capital-gains-tax">Capital Gains Tax (CGT)</Link></li>
                                    <li><Link to="/services/tax-service/goods-and-services-tax">Goods and Services Tax (GST) </Link></li>
                                    <li><Link to="/services/tax-service/fringe-benefits-tax">Fringe Benefits Tax (FBT)</Link></li>
                                    <li><Link to="/services/tax-service/income-tax-return-preparation">Income Tax Return Preparation</Link></li>
                                    <li><Link to="/services/tax-service/indirect-tax">Indirect Tax</Link></li>
                                    <li><Link to="/services/tax-service/r&d-tax-and-government-incentives">R&amp;D Tax and Government Incentives</Link></li>
                                    <li><Link to="/services/tax-service/strategic-tax-planning">Strategic Tax Planning</Link></li>
                                    <li><Link to="/services/tax-service/tax-solutions">Tax Solutions (including Complete Tax Solutions)</Link></li>
                                    <li><Link to="/services/tax-service/transfer-pricing">Transfer Pricing</Link></li>
                                    <li><Link to="/services/tax-service/tax-audit-preparations-and-advisory">Tax Audit Preparations and Advisory </Link></li>
                                  </ul>{/* /.submenu */}
                                </li>
                                <li><Link onClick={() => {window.location.href="/services/online-accounting"}}>Online Accounting</Link></li>
                                <li><Link onClick={() => {window.location.href="/services/payroll-accounting"}}>Payroll </Link></li>
                                <li><Link onClick={() => {window.location.href="/services/compliance-services"}}>Compliance </Link></li>
                                <li><Link onClick={() => {window.location.href="/services/superannuation-accounting-services"}}>Superannuation </Link></li>
                                <li><Link onClick={() => {window.location.href="/services/bookkeeping-services"}}>Bookkeeping Services</Link></li>
                                <li><a href="#">Business Tax Preparation</a></li>
                              </ul>{/* /.submenu */}
                            </li>
                            <li><a href="#">Resources</a>
                              <ul className="submenu"> 
                                {/* <li><a href="#">Tools &amp; Calculators </a>
                                </li> */}
                                <li><Link to="/useful-links">Useful Links</Link>
                                </li>
                                {/* <li><a href="#">Download Forms</a>
                                </li> */}
                                {/* <li><a href="#">Tax Facts</a>
                                </li> */}
                               
                              </ul>{/* /.submenu */}
                            </li>
                            <li><Link to="/about">About</Link>
                            </li>     
                            <li><Link to="/blog">Blog</Link>
                            </li>                       
                            <li><Link to="/contact-us">Contact Us</Link>
                            </li>                       
                          </ul>{/* /.menu */}
                        </nav>{/* /.mainnav */}  
                        <div className="btn-menu">
                          <span />
                        </div>{/* //mobile menu button */} 
                      </div>{/* /.nav-wrap */}
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>


           </Fragment>
        )
    }
}

export default withRouter(Header);