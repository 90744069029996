import PropTypes from "prop-types";
import React from "react";
import servicesData from "../data/our-services.json";
import OurServicesSingle from "../components/pages/single/OurServicesSingle";

const OurServices = () => {
  return (
    <section className="flat-row v1" id="services">
    <div className="container">
      <div className="row">
        <div className="col-md-12">  
          <div className="title-section style3 text-center">
            <h1 className="title">Our Services</h1>
          </div>      
        </div>{/* /.col-md-12 */}  
      </div>{/* /.row */} 
      <div className="row">
        {/* SLIDE 1 */}
          {servicesData &&
            servicesData.map((single, key) => {
              return (
                <OurServicesSingle
                  data={single}
                  key={key}
                  spaceBottomClass="mb-30"
                />
              );
            })}
            </div>{/* /.row */}
        </div>
      </section>
  );
};

OurServices.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default OurServices;
