import React,  { Component, Fragment } from 'react';
import footerlogo from '../../assets/images/logofooter3.png';
import whatsapp from '../../assets/images/whatsapp.png';
import {Link} from "react-router-dom";
class Footer extends Component {
    render() {
        return (
           <Fragment>
                         <footer className="footer widget-footer">
          <div className="container">
            <div className="row"> 
              <div className="col-lg-4 col-sm-6 reponsive-mb30">  
                <div className="widget-logo">
                  <div id="logo-footer" className="logo">
                    <a href="index.html" rel="home">
                      <img src={ footerlogo }/>
                    </a>
                  </div>
                  <p>You can get the proper management of tax planning as well as financial planning when you have the SMS accounting partner by your side. </p> 
                  <ul className="flat-information">
                    <li><i className="fa fa-map-marker" /><a href="#">4 langhorne street Dandenong Vic 3175
                      </a></li>
                    <li><i className="fa fa-phone" /><a href="#"> (03) 95309750</a></li>
                    <li><i className="fa fa-envelope" /><a href="#">info@smstax.com.au</a></li>
                  </ul>   
                    
                </div>         
              </div>{/* /.col-md-3 */} 
              <div className="col-lg-4 col-sm-6 reponsive-mb30">  
                <div className="widget widget-out-link clearfix">
                  <h5 className="widget-title">Our Services</h5>
                  <ul className="one-half">
                    <li><a href="/services/business-advisory">Business Advisory</a></li>
                    <li><Link to="/services/online-accounting">Online Accounting</Link></li>
                    <li><Link to="/services/bookkeeping-services">Bookkeeping Services</Link></li>
                    <li><Link to="/services/compliance-services">Compliance</Link></li>
                    
                  </ul>
                  <ul className="one-half">
                    <li><Link to="/services/tax-services">Tax Service</Link></li>
                    <li><Link to="/services/payroll-accounting">Payroll</Link></li>
                    <li><Link to="/">Tax Preparation</Link></li>
                  </ul>
                </div>
           
              </div>{/* /.col-md-3 */}
              <div className="col-lg-4 col-sm-6 reponsive-mb30">
                <div className="widget widget-letter">
                  <h5 className="widget-title">Disclaimer</h5>
                  <p className="info-text">Liability limited by a scheme approved under Professional Standards Legislation</p>
                  {/* <form id="subscribe-form" className="flat-mailchimp" method="post" action="#" data-mailchimp="true">
                    <div className="field clearfix" id="subscribe-content"> 
                      <p className="wrap-input-email">
                        <input type="text" tabIndex={2} id="subscribe-email" name="subscribe-email" placeholder="Enter Your Email" />
                      </p>
                      <p className="wrap-btn">
                        <button type="button" id="subscribe-button" className="flat-button subscribe-submit" title="Subscribe now">SUBSCRIBE</button>
                      </p>
                    </div>
                    <div id="subscribe-msg" />
                  </form> */}
                </div>
              </div>{/* /.col-md-3 */}
            </div>{/* /.row */}    
          </div>{/* /.container */}
        
        </footer>
        {/* Bottom */}
        <div className="bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-8">
                <div className="copyright"> 
                  <p >Copyright@ 2016-22 SMS Accounting Partners. All rights reserved. <Link to="/privacy-policy">Privacy Policy</Link> | Design by <a href="https://www.explosionweb.co.in/" target="_blank" rel="noreferrer">Explosionweb Solutions</a>
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <ul className="social-links style2 text-right">
                  <li><a href="https://www.facebook.com/SMSForTax" target="_blank" rel="noreferrer"><i className="fa fa-facebook" /></a></li>
                  <li><a href="https://twitter.com/smsaccounting1" target="_blank" rel="noreferrer"><i className="fa fa-twitter" /></a></li>
                  <li><a href="https://www.linkedin.com/company/smsaccounting-partners/" target="_blank" rel="noreferrer"><i className="fa fa-linkedin" /></a></li>
                  <li><a href="https://www.instagram.com/sms_accounting_partners/" target="_blank" rel="noreferrer"><i className="fa fa-instagram" /></a></li>
                 
                </ul> 
              
              </div>
            </div>          
          </div>{/* /.container */}
        </div>{/* bottom */}
        <div className='whats-app-link'>
        <a href='https://api.whatsapp.com/send?phone=61413467322' target="_blank" rel="noreferrer">
          <img src={whatsapp} alt=''/>
        </a>
      
    </div>
        {/* Go Top */}
        <a className="go-top">
          <i className="fa fa-angle-up" />
        </a>
           </Fragment>
        )
    }
}

export default Footer
