import React,  { Component, Fragment } from 'react';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import TaxServices from '../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class SuperannuationAccounting extends Component {
    render() {
      document.title = 'SMS : : Services - Superannuation Accounting';
      document.getElementsByTagName("META")[3].content = 'SMS : : Services - Superannuation Accounting';
      document.getElementsByTagName("META")[4].content = 'SMS : : Services - Superannuation Accounting';
        return (
           <Fragment>
            <Header />
                      {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Superannuation Accounting Services</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li>Superannuation Accounting Services</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-section style3 left">
                <h1 className="title">Superannuation Accounting Services</h1>
              </div>
            </div>
            <div className="col-12 paratext">
              <p>
                We offer the Superannuation Accounting Services by a specialist SMSF administrator who accepts engagement from financial planning firms, dealer groups, accounting &amp; advisory firms and direct clients. We are the specialist professionals to provide a full end to end administration services. We can do so for SMSF, including ongoing tax &amp; compliance advice and services.</p>
              <p>The remarkable part of our Superannuation Accounting Services is that we believe in delivering value for their clients. Besides, we also have a competitive and transparent fee schedule. We are also specialised in working on fixed-fee engagements.</p>
              <p> We can also ensure committing to providing high-end satisfaction to our clients. With our work, we ensure providing a comprehensive administration and trust setup service. We do so by taking into consideration the self-managed superannuation funds. Fields of administration and compliance are our speciality. We do everything by bringing together expertise in accounting, taxation, superannuation, and auditing.</p>
            </div>
          </div>
          <div className="row rowLine">
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">1</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content opened">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Establishment of a New Fund</h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>With our specialised professionals, we work for the paperwork necessary to get your Fund up and running. We also ensure taking care of the trust deed as a key part of the establishment service. We also have everything specifically developed to accommodate all of the latest estate planning strategies. In our work, we will also take care of the pension or accumulation phase. Even if the case is to deal with a mixture of both, we take care of it. </p>
                          <p> The specialised Superannuation Services ensure taking into consideration of Trust Deed. We also consider the Tax File Number and Australian Business Number Application. We can also include GST registration, if applicable. We can also consider Minutes, Membership Application forms, as well as Member Advice Notices.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">2</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">On-going Administration</h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>We can also deal with the information relating to all of your Fund’s transactions. Our administration and compliance service includes the Establishment and maintenance of the members’ records, accounting records, and trustee minutes. With our work, we will also Monitor compliance with an investment strategy. The best part is that we also deal with the preparation of annual financial statements and member statements. </p>
                          <p>We can Prepare and arrange the lodgement of the annual tax return. Besides these, we also Prepare and lodge business activity statements. Our speciality is the best in terms of instalment activity statements. We can also excel in Providing advice on compliance with the Trust Deed. In work, we also consider the SIS Act and Regulations. We can help to Organise an independent audit of the Fund. Besides, we also have actuarial certificates (if and when required). Applicable reporting and Fund GST registration is our speciality too. </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">3</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Pension Calculations and Documents </h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>
                            Superannuation Services that we work with ensuring that specialists can work with all types of pensions. It is inclusive of the account-based pensions, complying pensions, market-linked pensions allocated pensions, and transition to retirement pensions. You can rest assured that the Superannuation Services we excel with can go ahead with preparing all of the required paperwork. For the work, we can also start and maintain your pension. Our speciality lies in Pension setup documents, Member pension request, Trustee pension offer, Pension acceptance, Product disclosure statement, to name a few. 
                          </p> 
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </section>
       
             <Footer/>
           </Fragment>
        )
    }
}

export default SuperannuationAccounting